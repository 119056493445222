import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Table, Form, Input, Typography, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import { SearchIcon } from '../shared/icons';
import { API } from '../../AxiosWrapper';
import { checkPermission, stringComparison, filterObjectValues } from '../../Utils';
import { PERMISSION } from '../../Constants';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import ExportToExcel from '../shared/ExportToExcel'

const { Item } = Form;
const { Title } = Typography

const Companies = ({ permissions }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');

  const getCompanies = () => {
    setLoading(true);
    API.GET('Company')
      .then((response) => setCompanies(response.data))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  useEffect(getCompanies, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderAddress = (val, rec) => {
    const { street, postalCode, city } = rec.information
    if (!street && !postalCode && !city) return ''
    return `${street || ''}, ${postalCode || ''} ${city || ''}`
  }

  const editLink = (item) => (
    <NavLink to={'/admin/create-company?id=' + item.id} className="btn-link">
      <FormattedMessage id="Admin.Edit" />
    </NavLink>
  );

  const columns = [
    {
      dataIndex: ['information', 'name'],
      title: f('AccessGroups.Name'),
      sorter: (a, b) => stringComparison(a.information.name, b.information.name),
      width: 200,
    },
    {
      dataIndex: ['information', 'identifier'],
      title: f('Senders.IdentificationNumber'),
      sorter: (a, b) => stringComparison(a.information.identifier, b.information.identifier),
      width: 100,
    },
    {
      dataIndex: 'address',
      title: f('CreateDelivery.Address'),
      sorter: (a, b) => stringComparison(a.information?.street, b.information?.street),
      render: renderAddress,
      width: 200,
    },
    {
      dataIndex: ['information', 'country'],
      sorter: (a, b) => stringComparison(a.information?.country, b.information?.country),
      title: f('CreateCompany.Country'),
      width: 100,
    },
    {
      title: f('Table.Actions'),
      key: 'actions',
      render: (text, item) => checkPermission(permissions, PERMISSION.editCompany) ? editLink(item) : '',
      width: 100,
    },
  ];

  const searchableProps = [
    ['information', 'name'], ['information', 'identifier'], ['information', 'street'],
    ['information', 'postalCode'], ['information', 'city'], ['information', 'country'],
  ];

  const dataSource = companies
    .filter((obj) => filterObjectValues(obj, searchableProps, searchString));

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.Companies" /></Title>

      <Form layout="vertical">
        <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
          <Input
            className="standard-input"
            suffix={<SearchIcon />}
            onChange={e => setSearchString(e.target.value)}
            placeholder={f('Input.SearchList')}
          />
        </Item>
      </Form>

      <Row>
        <Col span={8} >
          {checkPermission(permissions, PERMISSION.createCompany) &&
            <NavLink to="/admin/create-company" style={{ marginBottom: 16 }}>
              + <FormattedMessage id="Sidebar.CreateCompany" />
            </NavLink>
          }
        </Col>
        <Col span={8}></Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <ExportToExcel dataSource={dataSource} columns={columns} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content' }}
        loading={loading}
        rowKey={'id'}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} ${f('Pagination.Of')} ${total}`,
          showSizeChanger: true,
        }}
        sticky
      />

    </Layout.Content>
  );
};

export default Companies;
