import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Form, Button, Input, Radio, Checkbox } from 'antd';
import { some } from 'lodash';

import { SHOW_SHELF_BUTTON, PERMISSION } from '../../Constants';
import { checkPermission, getSanitizedPhone } from '../../Utils';
import { API } from '../../AxiosWrapper';
import RegisterModal from './RegisterModal';
import LockerBoxes from '../shared/LockerBoxes';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form;

const RegisterTabs = ({ form, permissions, setLoading, branchId, companyId, defaultBranchId, lockers }) => {

  const history = useHistory();
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();

  const [modalVisible, setModalVisible] = useState(false);
  const [radioValue, setRadioValue] = useState(1)
  const [returningOld, setReturningOld] = useState(false)

  const selectButtonLabel = f('CreateDelivery.BoxSizeSelectAt1') + ' ' + f('CreateDelivery.BoxSizeSelectAt2');

  const validate = () => {
    form.validateFields()
      .then((values) => {
        if (branchId === defaultBranchId) handleSubmit();
        else setModalVisible(true);
      })
  };

  const getBoxSize = (boxSize) => boxSize === 'none' ? null : boxSize;

  const openPage = (businessKey) => {
    setModalVisible(false);
    history.push('/about-delivery/?businessKey=' + businessKey);
  };

  const handleSubmit = () => {
    const box = radioValue === 1;
    const shelf = radioValue === 2;
    setLoading(true);
    form.validateFields()
      .then((values) => {
        const getRelatedInfo = () => {
          if (!returningOld) return null;
          if (box) return {
            boxSize: values.oldBoxSize,
            description: values.oldItemDescription
          };
          if (shelf) return {
            location: values.oldLocation,
            description: values.oldItemDescription
          };
        }
        const newData = {
          deliveryBranchId: branchId,
          senderOrderID: values.senderOrderID,
          companyId: companyId,
          description: values.description || f("CreateDelivery.NoDescription"),
          senderId: values.senderId,
          recipient: {
            identificationNumber: values.kennitala?.replace(/\D/g, ''),
            name: values.recipient,
            email: values.email,
            phone: getSanitizedPhone(values.phone),
            street: values.street,
          },
          numberOfPackages: 1,
          createDate: values.createDate,
          location: shelf ? values.location : null, // only for shelf
          box,
          boxSize: box ? getBoxSize(values.boxSize) : null, // only for box
          relatedDeliveryInfo: getRelatedInfo(),
          rentalCar: values.rentalCar,
        };

        API.POST('Delivery', newData)
          .then(response => openPage(response.data))
          .catch(handleApiError)
          .finally(() => setLoading(false));
      })
  }

  const renderBox = () => (
    <>
      {some(lockers, l => l.lockerType === "Apex") && (
        <LockerBoxes lockers={lockers} selectButtonLabel={selectButtonLabel} />
      )}

      {returningOld &&
        <Item
          name="oldItemDescription"
          rules={[{ required: false, whitespace: true }]}
          label={<FormattedMessage id="CreateDelivery.OldItemDescription" />}
        >
          <Input />
        </Item>
      }
      {returningOld && some(lockers, l => l.lockerType === "Apex") && (
        <LockerBoxes lockers={lockers} name="oldBoxSize" />
      )}

      <Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={validate}
          shape="round"
        >
          <FormattedMessage id="CreateDelivery.Confirm" />
        </Button>
      </Item>
    </>
  );

  const renderShelf = () => (
    <>
      <Item
        name="location"
        rules={[{ type: 'string', required: true, message: f('CreateDelivery.ShelfRequired') }]}
        label={<FormattedMessage id="CreateDelivery.ShelfPlaceholder" />}
      >
        <Input />
      </Item>

      {returningOld &&
        <>
          <Item
            name="oldItemDescription"
            rules={[{ required: false, whitespace: true }]}
            label={<FormattedMessage id="CreateDelivery.OldItemDescription" />}
            style={{ marginBottom: 40 }}
          >
            <Input />
          </Item>
          <Item
            name="oldLocation"
            rules={[{ type: 'string', required: true, message: f('CreateDelivery.ShelfRequired') }]}
            label={<FormattedMessage id="CreateDelivery.ShelfPlaceholder" />}
          >
            <Input />
          </Item>
        </>
      }
      <Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={validate}
          shape="round"
        >
          <FormattedMessage id="CreateDelivery.AssignShelf" />
        </Button>
      </Item>
    </>
  );

  return (
    <>
      <RegisterModal
        handleOK={handleSubmit}
        handleCancel={() => setModalVisible(false)}
        open={modalVisible}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
        <Radio.Group
          className="register-tabs-radio"
          onChange={e => setRadioValue(e.target.value)}
          value={radioValue}
        >
          <Radio value={1}>{f('CreateDelivery.Box')}</Radio>
          {SHOW_SHELF_BUTTON &&
            <Radio value={2}>{f('CreateDelivery.Shelf')}</Radio>
          }
        </Radio.Group>
        {checkPermission(permissions, PERMISSION.createRelatedShipmentDeliveryForSender)
          && (
            <Checkbox checked={returningOld} onChange={e => setReturningOld(e.target.checked)}>
              {f('CreateDelivery.Return')}
            </Checkbox>
          )}
      </div>

      {radioValue === 1 ? renderBox() : renderShelf()}
    </>
  )
}

export default RegisterTabs;
