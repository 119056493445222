import React from 'react';
import { Button, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const RegisterModal = ({ open, handleOK, handleCancel }) => {
  return (
    <Modal
      onOk={handleOK}
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
        >
          <FormattedMessage id="CreateDelivery.No" />
        </Button>,
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          onClick={handleOK}
        >
          <FormattedMessage id="CreateDelivery.Yes" />
        </Button>,
      ]}
    >
      <p>
        <WarningOutlined style={{ color: '#FF9871', fontSize: '16px' }} /> 
        {' '}
        <FormattedMessage id="CreateDelivery.NotDefaultBranch" />
      </p>
    </Modal>
  );
}

export default RegisterModal;
