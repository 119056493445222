import { Table } from 'antd'
import { useHistory } from 'react-router-dom';
import { useFormatMessage } from '../shared/hooks'

const DeliveryTable = ({ columns, dataSource, loading, pageSize, scrollY, pagination = true })=> {
  const history = useHistory();
  const f = useFormatMessage()

  return (
    <Table
    className='clickable' // table-ellipsis
    columns={columns}
    dataSource={dataSource}
    loading={loading}
    pagination={pagination && {
      showTotal: (total, range) => `${range[0]}-${range[1]} ${f('Pagination.Of')} ${total}`,
      pageSize: pageSize,
      showLessItems: true,
      showSizeChanger: false,

    }}
    scroll={{ x: 'max-content', y: scrollY }}
    rowKey={'businessKey'}
    sticky
    onRow={record => ({
      onClick: () => history.push('/about-delivery/?businessKey=' + record.businessKey)
    })}
  />
  )
}

export default DeliveryTable