import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Table, Form, Input, Row, Col, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkPermission, stringComparison, filterObjectValues } from '../../Utils';
import { PERMISSION } from '../../Constants';
import { SearchIcon } from '../shared/icons';
import ExportToExcel from '../shared/ExportToExcel'

const { Item } = Form;
const { Title } = Typography

const Branches = ({ branches, permissions }) => {
  const intl = useIntl();
  const f = (id) => intl.formatMessage({ id })

  const [searchString, setSearchString] = useState('');

  const editLink = (item) => (
    <NavLink to={'/admin/create-branch?id=' + item.id} className="btn-link">
      <FormattedMessage id="Admin.Edit" />
    </NavLink>
  )

  const columns = [
    {
      dataIndex: 'name',
      title: f('AccessGroups.Name'),
      sorter: (a, b) => stringComparison(a.name, b.name),
      width: 200,
    },
    {
      dataIndex: 'address',
      title: f('InfoSender.Address'),
      sorter: (a, b) => stringComparison(a.address?.street, b.address?.street),
      render: (val) => `${val?.street}, ${val?.postalCode} ${val?.city}`,
      width: 200,
    },
    {
      dataIndex: 'companyName',
      title: f('ActionDeliveries.TH.Company'),
      sorter: (a, b) => stringComparison(a.companyName, b.companyName),
      width: 200,
    },
    {
      title: f('Table.Actions'),
      key: 'actions',
      render: (text, item) => checkPermission(permissions, PERMISSION.editBranch) ? editLink(item) : '',
      width: 100,
    },
  ];

  const searchableProps = ['name', ['address', 'street'], ['address', 'postalCode'], ['address', 'city'], 'companyName'];

  const dataSource = branches
    .filter((obj) => filterObjectValues(obj, searchableProps, searchString));

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.Branches" /></Title>

      <Form layout="vertical">
        <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
          <Input
            className="standard-input"
            suffix={<SearchIcon />}
            onChange={e => setSearchString(e.target.value)}
            placeholder={f('Input.SearchList')}
          />
        </Item>
      </Form>

      <Row>
        <Col span={8} >
          {checkPermission(permissions, PERMISSION.createBranch) &&
            <NavLink to="/admin/create-branch" style={{ marginBottom: 16 }}>
              + <FormattedMessage id="Button.AddBranch" />
            </NavLink>
          }
        </Col>
        <Col span={8}></Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <ExportToExcel dataSource={dataSource} columns={columns} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content' }}
        rowKey={'id'}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} ${f('Pagination.Of')} ${total}`,
          showSizeChanger: true,
        }}
        sticky
      />
    </Layout.Content>
  );
};

export default Branches;
