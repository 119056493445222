import { useIntl } from 'react-intl';
import { message, theme } from 'antd';

/**
 *  @param {id} Message id
 *  @param {values} Values object of the formatted message
 */
export const useFormatMessage = () => {
  const { formatMessage } = useIntl()
  return (id, values) => formatMessage({ id }, values)
}

/**
 * Translates errorCode from API
 * logic: 
 ** if there is a translatable errorCode, there should be no errorMessage
 ** if there is an errorMessage, show it first, because there is no translatable error code
 *  @param {error} object
 */
export const useHandleApiError = () => {
  const f = useFormatMessage()

  return (error, name = 'this request') => {
    const { response } = error
    if (response?.status === 401) return // no error message, just redirect

    let text = ''
    
    if (!response) 
      text = f('Error.Unknown')
    else if (response.status === 403)
      text = `Permissions missing for ${name}`
    else if (response.data?.errorMessage)
      text = response.data.errorMessage
    else if (response.data?.errorCode)
      text = f(response.data.errorCode)
    else 
      text = `${f('Error')}: ${response.status} ${response.statusText}`

    message.error(text)
  }
}

/** return current Ant design theme - 0: light, 1: dark */
export const useTheme = () => {
  const token = theme.useToken()
  return token.theme.id === 0 ? 'light' : 'dark'
}
