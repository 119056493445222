import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, Table, Typography } from 'antd';
// import 'dayjs/locale/is';

import ErrorNotFound from '../ErrorNotFound';
import BarcodeRow from './BarcodeRow';
import { columns } from './columns';
import { canPickupFromBox, canPutInBox, canReclaim } from './utils';

const { Title } = Typography

const ShippingHistory = ({ history, loading, stateId, deliveryCode, delivered, deliveryCodeUrl, pickupCode, pickupCodeUrl, reclaimCode, reclaimCodeUrl, showError, relatedDeliveryBusinessKey }) => {

  return (
    <div>
    <Form>
      <Form.Item>
        <div>
          <Title level={3}><FormattedMessage id="AboutDelivery.ShippingHistory" /></Title>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={history}
            pagination={false}
            showHeader={false}
            loading={loading}
            scroll={{ y: 350, x: 'max-content' }}
          />
        </div>
      </Form.Item>

      {canPutInBox(stateId) && deliveryCode && !delivered
        ? <BarcodeRow
          barCodeVal={deliveryCode}
          qrCodeVal={deliveryCodeUrl}
          out={false}
        />
        : null}
      {canPickupFromBox(stateId) && pickupCode && !delivered
        ? <BarcodeRow
          barCodeVal={pickupCode}
          qrCodeVal={pickupCodeUrl}
          out={true}
        />
        : null}
      {canReclaim(stateId) && reclaimCode && !delivered
        ? <BarcodeRow
          barCodeVal={reclaimCode}
          qrCodeVal={reclaimCodeUrl}
          out={true}
        />
        : null}
    </Form>

    {showError
      ? <div><ErrorNotFound /></div>
      : null
    }

    {relatedDeliveryBusinessKey
      ? (
        <div>
          <span>Tengd sending: </span>
          <Link to={'/about-delivery/?businessKey=' + relatedDeliveryBusinessKey}>{relatedDeliveryBusinessKey}</Link>
        </div>
      ) : null
    }
  </div>
  )
}

export default ShippingHistory;
