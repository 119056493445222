import dayjs from 'dayjs';
import { get, toLower, includes } from 'lodash';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { PERMISSION } from './Constants'

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const regionCodePhone = "IS";

export function addKeysToRows(rows) {
  rows.forEach((oneLine, index) => {
    oneLine.key = index;
  });
  return rows;
}

export function dateComparison(a, b, lookup = null) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if (lookup) {
    a = lookup(a);
    b = lookup(b);
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
  }
  let momA = dayjs(a);
  let momB = dayjs(b);
  if (momB.isBefore(momA)) return -1;
  return momA.isBefore(momB);
}

export function stringComparison(a, b, lookup = null) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  if (lookup) {
    a = lookup(a);
    b = lookup(b);
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
  }
  return a.localeCompare(b);
}

export function boolComparison(a, b) {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
}

export function filterData({ senderId, showOnlyDelivered, startDate, endDate }, lines) {
  let filteredData = lines;
  if (senderId) {
    filteredData = filteredData.filter(
      (line) => line.sender && line.sender.id === senderId
    );
  }
  if (showOnlyDelivered) {
    filteredData = filteredData.filter((line) => line.delivered);
  }
  if (startDate) {
    filteredData = filteredData.filter((line) =>
      dayjs(line.createDate).isSameOrAfter(startDate)
    );
  }
  if (endDate) {
    filteredData = filteredData.filter((line) =>
      dayjs(line.createDate).isSameOrBefore(endDate)
    );
  }
  return filteredData;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const formSearchItemLayout = {
  labelCol: { xs: { span: 24 }, md: { span: 24 }, lg: { span: 6 } },
  wrapperCol: { xs: { span: 24 }, md: { span: 14 } },
};

export const checkPermission = (permissions, permission) => {
  if (permissions.includes(PERMISSION.accessAll)) return true;
  return permissions.includes(permission);
};

export const filterObjectValues = (obj, searchableProps, searchString = '') => searchableProps.some(
  key => includes(toLower(get(obj, key, '')), toLower(searchString))
)

export const getSanitizedPhone = (phone) => {
  if (!phone) return null;

  const phoneUtil = PhoneNumberUtil.getInstance();
  phone = phone.replace(/[^a-zA-Z0-9 ]/g, "");
  const numberChk = phoneUtil.parseAndKeepRawInput(phone, regionCodePhone);
  return numberChk.getNationalNumber();
};

export const isValidPhone = (value) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(value, regionCodePhone);
  return phoneUtil.isValidNumberForRegion(number, regionCodePhone);
}

const validRange = value => value.match(/\d/g)?.length >= 3 && value.match(/\d/g)?.length <= 13;

export const validatePhone = (_, value) => {
  if (!value || validRange(value)) {
    return Promise.resolve();
  }
  return Promise.reject();
}

export const phoneValidator = (rule, value) => {
  // allow empty phone number. Required should take care of that
  if (!value) return Promise.resolve()

  return isValidPhone(value)
    ? Promise.resolve()
    : Promise.reject()
}
