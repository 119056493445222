import React, { useState, useCallback } from 'react';
import { Input, AutoComplete } from 'antd';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { debounce, isEmpty } from 'lodash';

import { API } from '../../AxiosWrapper';
import { SearchIcon } from '../shared/icons'

const HeaderSearch = ({ history }) => {
  const intl = useIntl();
  const f = (id) => intl.formatMessage({ id })

  const [searchString, setSearchString] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const handleSelect = (optionValue) => {
    // due to the fact that onInputKeyDown is ALWAYS fired, 
    // even when the focus is on a dropdown item, 
    // we are going to replace the history if it has search-shipment in it
    // this is a HACK, but the behavior seems to work
    const path = '/about-delivery/?businessKey=' + optionValue;
    if (history.location.pathname === '/search-shipment/') {
      history.replace(path);
    } else {
      history.push(path);
    }
  }

  const onEnter = (searchString) => {
    history.push('/search-shipment/?searchString=' + searchString)
  }

  const search = (searchString) => {
    let queryUrl = 'Delivery?limit=8&searchString=' + searchString;
    API.GET(queryUrl)
      .then((response) => {
        setSuggestions(response.data);
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(searchString => search(searchString), 300),
    [], // will be created only once initially
  );

  const handleSearch = (searchString) => {
    setSearchString(searchString);
    if (searchString.length < 2) {
      setSuggestions([]);
      return;
    }
    debouncedSearch(searchString);
  }

  let match = null

  function traverseForMatch(obj) {
    for (let key in obj) {
      const value = obj[key];
      if (typeof value === 'string' && value.toLowerCase().includes(searchString.toLowerCase())) {
        match = { key, value };
        return;
      }
      if (value && typeof value === 'object') {
        traverseForMatch(value);
      }
    }
  }

  const mapSuggestion = (delivery) => {
    traverseForMatch(delivery);
    if (isEmpty(match)) return delivery.recipient.name;

    const re = new RegExp(searchString, "gi");
    const replaced = match.value.replace(re, (match) => "<b>" + match + "</b>");
    return (
      <span>
        <i>{match.key}</i> : <span dangerouslySetInnerHTML={{ __html: replaced }} /> | {delivery.recipient.name} | {delivery.description}
      </span>
    )
  }

  const children = suggestions.map(delivery => ({
    label: mapSuggestion(delivery),
    value: delivery.businessKey,
  }))

  const onPressEnter = () => {
    if (searchString?.length > 0) {
      onEnter(searchString);
    }
  }

  return (
    <AutoComplete
      style={{width: '100%' }}
      options={children}
      onSearch={handleSearch}
      onSelect={handleSelect}
      defaultActiveFirstOption={false}
      popupMatchSelectWidth={false}
    >
      <Input
        placeholder={f('Header.Search.Placeholder')}
        suffix={<SearchIcon onClick={onPressEnter} />}
        onPressEnter={onPressEnter}
      />
    </AutoComplete>
  );
}

export default withRouter(HeaderSearch);