import React, { useEffect, useState } from 'react';
import { Row, Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { API } from '../../AxiosWrapper';
import { actionableStates } from './helpers';
import { columns } from './Columns';
import { PERMISSION } from '../../Constants';
import { checkPermission } from '../../Utils';
import { labelStyle } from './style';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import DeliveryTable from '../shared/DeliveryTable'

const { Text } = Typography

const initialPageSize = 7
const pollingInterval = 60000 // 1 min

const ActionableDeliveries = ({ branches, branchId, permissions, allowedColumns = new Map()}) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()

  const [pageSize, setPageSize] = useState(initialPageSize)
  const [deliveries, setDeliveries] = useState([])
  const [loading, setLoading] = useState()

  const getDeliveryData = (branchId) => {
    setLoading(true)
    const url = `Delivery?Delivered=false${branchId ? `&branchId=${branchId}` : ''}`
    API.GET(url).then(({ data }) => {
      const deliveries = data.reverse().filter(d => actionableStates.has(d?.currentState?.stateId))
      setDeliveries(deliveries)
    })
    .catch(handleApiError)
    .finally(() => setLoading(false))
  };

  useEffect(() => {
    const interval = setInterval(() => getDeliveryData(branchId), pollingInterval)

    getDeliveryData(branchId);

    return () => {
      clearInterval(interval);
    }
  }, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  const getBranchName = branchId => branches.find(b => b.id === branchId)?.name

  deliveries.forEach(obj => {
    obj.branchName = getBranchName(obj.deliveryBranchId)
  })

  const seeLessButton = <Button type="link" onClick={() => setPageSize(initialPageSize)}>{f('Table.SeeLess')}</Button>;

  const seeAllButton = <Button type="link" onClick={() => setPageSize(deliveries.length)}>{f('Table.SeeAll')}</Button>;

  const initialColumns = columns
    .filter(col => col.key === 'orderType' ? checkPermission(permissions, PERMISSION.readCollection) : true)
    .filter(col => allowedColumns.has(col.key) ? allowedColumns.get(col.key).used : true)
    // .map(col => ({ ...col, visible: defaultVisibleColumns.includes(col.key) }))

  return (
    <>
      <Text style={labelStyle}><FormattedMessage id="Home.ActionList" /></Text>
      {pageSize === deliveries.length && (
        <Row justify="center">
          {seeLessButton}
        </Row>
      )}
      <DeliveryTable
        columns={initialColumns}
        dataSource={deliveries}
        loading={loading}
        pageSize={pageSize}
      />
      {(deliveries.length > 0) &&
        <Row className="see-all-row" justify="center">
          {pageSize === deliveries.length ? seeLessButton : seeAllButton}
        </Row>
      }
    </>
  );
};

export default ActionableDeliveries;
