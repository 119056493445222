import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Radio, Form } from 'antd';
import { map } from 'lodash';

const { Item } = Form;

export const sizeTranslations = {
  "3x3": 'Box.Size.Keys',
  "11x4": 'Box.Size.Small',
  "12x4": 'Box.Size.Small',
  "11x10": 'Box.Size.Mid',
  "12x10": 'Box.Size.Mid',
  "11x16": 'Box.Size.Big',
  "12x16": 'Box.Size.Big',
  "11x34": 'Box.Size.Big',
  "12x34": 'Box.Size.Big',
  "11x69": 'Box.Size.Super',
  "12x69": 'Box.Size.Super',
}

const lockerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 72,
  minWidth: 65,
  maxWidth: 100,
  lineHeight: '210%',
};

const LockerBoxes = ({ lockers, selectButtonLabel, name = 'boxSize' }) => {
  const intl = useIntl();
  const f = (id) => intl.formatMessage({ id })

  return (
    <Item
      name={name}
      label={<FormattedMessage id="CreateDelivery.BoxSize" />}
      rules={[{ required: true, message: 'Please select box size!' }]}
    >
      <Radio.Group
        size="large"
        className="lockers"
        buttonStyle="solid"
        style={{ display: 'flex', marginBottom: 16, textAlign: 'center' }}
      >
        {map(lockers, (item, i) => (
          <Radio.Button
            value={item.size}
            key={i}
            style={{ ...lockerStyle, minWidth: lockerStyle.minWidth + i * 10, marginRight: 20 }}
          >
            <div>{f(sizeTranslations[item.size])}</div>
            <div className="locker-size" style={{ fontSize: 'x-small', lineHeight: '0px' }}>{item.sizeText}</div>
            <div>{item.numAvailable}</div>
          </Radio.Button>
        ))}
        {selectButtonLabel && (
          <Radio.Button
            value={'none'}
            key={lockers.length}
            style={{ ...lockerStyle, lineHeight: 'normal', marginLeft: 'auto' }}
          >
            {selectButtonLabel}
          </Radio.Button>
        )}
      </Radio.Group>
    </Item>
  )
}

export default LockerBoxes
