import React, { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';

import { API } from '../../AxiosWrapper';
import FreeCompartments from './FreeCompartments';
import LockersCount from './LockersCount';
import DateRangeStats from './DateRangeStats';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Content } = Layout

const savedState = JSON.parse(localStorage.getItem('visibleSections'))

const Dashboard = ({ branches, branchId }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();
  
  const [loadingGeneralStats, setLoadingGeneralStats] = useState(false)
  const [generalStats, setGeneralStats] = useState({})
  const allSections = ['DateRangeStats', 'LockersCount', f('Home.FreeCompartments')]
  const [visibleSections, setVisibleSections] = useState(savedState || allSections)

  const getGeneralStats = () => {
    setLoadingGeneralStats(true)
    let url = 'Dashboard/general'
    if (branchId) url += `?branchId=${branchId}`;
    API.GET(url)
      .then(response => setGeneralStats(response.data))
      .catch(handleApiError)
      .finally(() => setLoadingGeneralStats(false))
  }

  useEffect(getGeneralStats, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  const shouldShow = name => visibleSections.includes(name)

  const branch = branches.find(b => b.id === branchId)

  // eslint-disable-next-line no-unused-vars
  const handleChange = values => {
    localStorage.setItem('visibleSections', JSON.stringify(values));
    setVisibleSections(values)
  }

  return (
    <Layout>
      <Content>
        {/* <Row style={{ marginBottom: 10 }}>
          <Checkbox.Group
            options={allSections}
            defaultValue={visibleSections}
            onChange={handleChange}
          />
        </Row> */}
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            {shouldShow('DateRangeStats') &&
              <DateRangeStats branchId={branchId} />
            }
          </Col>
          <Col xs={24} lg={12}>
            {shouldShow('LockersCount') &&
              <LockersCount generalStats={generalStats} loading={loadingGeneralStats} />
            }
            {shouldShow(f('Home.FreeCompartments')) &&
              <FreeCompartments
                data={generalStats?.freeCompartments}
                loading={loadingGeneralStats}
                branchName={branch?.name}
              />
            }
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Dashboard;
