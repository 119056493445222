import React, { useState, useEffect } from 'react';
import { Layout, Col, Form, Input, Row, Select, Typography, Checkbox } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/is';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import { API } from '../../AxiosWrapper';
import { blacklistedStatuses, translations } from '../../Constants';
import { addKeysToRows } from '../../Utils';
import ShippingHistory from './ShippingHistory';
import BottomButtons from './BottomButtons';
import PrintButton from './PrintButton';
import Barcode from 'react-barcode';
import { canPutInBox } from './utils';
import { useHandleApiError } from '../shared/hooks'

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography

dayjs.locale('is');

const barcodeLabelOptions = {
  height: 55,
  width: 2,
  marginLeft: 8,
  marginRight: 8,
  marginTop: 0,
  marginBottom: 1,
  displayValue: false,
  format: 'CODE128',
};

const AboutDelivery = ({ branches, senders, fieldConfiguration = new Map() }) => {
  const [form] = Form.useForm();
  const handleApiError = useHandleApiError();

  const [delivered, setDelivered] = useState(false);
  const [location, setLocation] = useState('');
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showError, setShowError] = useState(null);
  const [data, setData] = useState({});

  const setLocationOnField = (location) => {
    setLocation(location)
    form.setFieldValue('location', location)
  }

  const fieldShown = (name) => {
    return fieldConfiguration.get(name)?.used ?? false
  }

  const getFormData = (businessKey, showLoader = true) => {
    let url = 'Delivery/' + (businessKey ? businessKey : '');
    if (showLoader) setLoading(true);
    API.GET(url)
      .then(response => {
        let history = response.data.history.reverse();
        history = addKeysToRows(history);
        history = history.filter(row => !blacklistedStatuses.includes(row.topic));
        setData(response.data);
        setLocationOnField(response.data.location);
        setHistory(history);
      })
      .catch(error => {
        handleApiError(error);
        if (error.response) setShowError(true);
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    let businessKey = new URL(window.location).searchParams.get('businessKey');
    if (!businessKey) return;

    getFormData(businessKey);

    const interval = setInterval(() => {
      getFormData(businessKey, false);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handlePatch = () => {
    form.validateFields(['phone', 'email', 'senderId'])
      .then((values) => {
        const { senderId, phone, email } = values
        const url = 'Delivery/' + data.businessKey;
        const patchData = [
          {
            path: '/sender/id',
            op: 'replace',
            value: senderId,
          },
        ];
        API.PATCH(url, patchData)
          .then((response) => { })
          .catch(handleApiError);

        const recipientUrl = 'Delivery/' + data.businessKey + '/recipient';
        const recipientData = [
          {
            path: '/phone',
            op: 'replace',
            value: phone,
          },
          {
            path: '/email',
            op: 'replace',
            value: email,
          },
        ];
        API.PATCH(recipientUrl, recipientData)
          .then((response) => { })
          .catch(handleApiError);
      })
  }

  const handleSubmit = (businessKey, command) => {
    let url = 'Delivery/' + (businessKey ? businessKey : '') + '/' + command;

    form.validateFields(
      [
        'createDate', 'businessKey', 'senderId', 'recipientName', 'email', 'phone', 'street', 'senderOrderID', 'description', 'numberOfPackages', 'delivered',
      ])
      .then((values) => {
        const postData = {
          createDate: values.createDate,
          businessKey: values.businessKey,
          senderId: values.senderId,
          recipient: {
            name: values.recipientName,
            email: values.email,
            phone: values.phone,
            street: values.street,
          },
          senderOrderID: values.senderOrderID,
          description: values.description,
          numberOfPackages: values.numberOfPackages,
          location: location,
          delivered: delivered,
        };
        API.POST(url, postData)
          .then((response) => { })
          .catch(handleApiError);

        getFormData(businessKey);
      })
  }

  const handleChangeFormInput = () => {
    setEditing(false);
    handlePatch();
  };

  const handleEditingMode = () => {
    setEditing(true);
  };

  if (isEmpty(data)) return null;

  const stateId = get(data, 'currentState.stateId');
  const title = data.orderType === 'collection' ? "CollectionTitle" : "DeliveryTitle"

  const getBranchName = () => {
    const branch = branches.find(b => b.id === data.deliveryBranchId)
    return branch?.name || 'Útibú ekki skilgreint'
  }

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id={`CreateDelivery.${title}`} /></Title>
      <Row>
        <Col xs={24} xl={12}>
          <Form
            form={form}
            layout="vertical"
            className="form-style"
            initialValues={{
              senderId: get(data, 'sender.name'),
              email: get(data, 'recipient.email'),
              phone: get(data, 'recipient.phone'),
              kennitala: get(data, 'recipient.identificationNumber'),
              rentalCar: data.rentalCar,
              // createDate: data.createDate ? dayjs(data.createDate) : null,
              createDate: data.createDate ? dayjs(data.createDate).format('YYYY-MM-DD HH:mm') : null,
              createdByUserName: data.createdByUserName,
              recipientName: get(data, 'recipient.name'),
              businessKey: data.businessKey,
              street: get(data, 'recipient.street'),
              senderOrderID: data.senderOrderID,
              description: data.description,
              location: location,
              currentState: translations[stateId] || get(data, 'currentState.name'),
              apexStatus: data.apexStatus,
              branchName: getBranchName(),
            }}
          >
            <Item
              name="createDate"
              labelAlign="left"
              label={<FormattedMessage id="CreateDelivery.Date" />}
            >
              <Input readOnly disabled={editing} />
            </Item>
            <Item
              name="createdByUserName"
              labelAlign="left"
              label={<FormattedMessage id="CreateDelivery.CreatedByUserName" />}
              hasFeedback
            >
              <Input readOnly disabled={editing} />
            </Item>
            <Item
              labelAlign="left"
              label={<FormattedMessage id="ActionDeliveries.TH.BusinessKey" />}
              hasFeedback
              name="businessKey"
            >
              <Input readOnly disabled={editing} />
            </Item>
            <Item
              name="senderId"
              labelAlign="left"
              hidden={senders.length === 1 ? true : false}
              label={data.orderType === 'collection'
                ? <FormattedMessage id="CreateCollection.ReceivingCompany" />
                : <FormattedMessage id="CreateDelivery.Sender" />
              }
              hasFeedback
            >
              <Select
                readOnly={!editing}
                disabled={!editing}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children
                  .toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {senders && senders.map((sender) => (
                  <Option key={sender} value={`${sender.id}`}>
                    {sender.name}
                  </Option>
                ))}
              </Select>
            </Item>
            {fieldShown("recipientName") &&
              <Item
                name="recipientName"
                labelAlign="left"
                label={<FormattedMessage id="CreateCollection.Customer" />}
                hasFeedback
              >
                <Input readOnly disabled={editing} />
              </Item>
            }
            {fieldShown("kennitala") &&
              <Item
                name="kennitala"
                labelAlign="left"
                label={<FormattedMessage id="ActionDeliveries.TH.Kennitala" />}
                hasFeedback
              >
                <Input readOnly disabled={editing} />
              </Item>
            }
            {fieldShown("street") &&
              <Item
                name="street"
                labelAlign="left"
                label={<FormattedMessage id="CreateDelivery.Address" />}
                hasFeedback
              >
                <Input readOnly disabled={editing} />
              </Item>
            }
            {(fieldShown("email") || fieldShown("emailOrPhone")) &&
              <Item
                name="email"
                labelAlign="left"
                label={<FormattedMessage id="CreateDelivery.Email" />}
                hasFeedback
              >
                <Input type="email" readOnly={!editing} />
              </Item>
            }
            {(fieldShown("phone") || fieldShown("emailOrPhone")) &&
              <Item
                name="phone"
                labelAlign="left"
                label={<FormattedMessage id="CreateDelivery.Mobile" />}
                hasFeedback
              >
                <Input type="tel" readOnly={!editing} />
              </Item>
            }
            {fieldShown("senderOrderID") &&
              <Item
                name="senderOrderID"
                labelAlign="left"
                label={<FormattedMessage id="CreateDelivery.OrderNumber" />}
                hasFeedback
              >
                <Input readOnly disabled={editing} />
              </Item>
            }
            {fieldShown("description") &&
              <Item
                name="description"
                labelAlign="left"
                label={<FormattedMessage id="CreateDelivery.OrderDescription" />}
                hasFeedback
              >
                <Input readOnly disabled={editing} />
              </Item>
            }
            {fieldShown("rentalCar") &&
              <Item
                name="rentalCar"
                hasFeedback
                valuePropName="checked"
              >
                <Checkbox disabled={true}>
                  {<FormattedMessage id="ActionDeliveries.TH.RentalCar" />}
                </Checkbox>
              </Item>
            }
            <Item
              name="location"
              labelAlign="left"
              label={<FormattedMessage id="ActionDeliveries.TH.Location" />}
            >
              <Input readOnly disabled={editing} />
            </Item>
            <Item
              name="currentState"
              labelAlign="left"
              label={<FormattedMessage id="ActionDeliveries.TH.Status" />}
            >
              <Input readOnly disabled={editing} />
            </Item>
            <Item
              name="apexStatus"
              labelAlign="left"
              label={<FormattedMessage id="AboutDelivery.StatusInBox" />}
            >
              <Input readOnly disabled={editing} />
            </Item>
            {branches.length === 1 &&
              <Item
                name="branchName"
                labelAlign="left"
                label={<FormattedMessage id="AboutDelivery.Branches" />}
              >
                <Input readOnly disabled={editing} />
              </Item>
            }
            <BottomButtons
              form={form}
              stateId={stateId}
              editing={editing}
              handleEditingMode={handleEditingMode}
              handleChangeFormInput={handleChangeFormInput}
              businessKey={data.businessKey}
              handleSubmit={handleSubmit}
              setLocation={(val) => setLocationOnField(val)}
              setDelivered={(val) => setDelivered(val)}
            />
          </Form>
        </Col>
        <Col xs={24} xl={10}>
          <ShippingHistory
            history={history}
            loading={loading}
            stateId={stateId}
            deliveryCode={data.deliveryCode}
            delivered={delivered}
            deliveryCodeUrl={data.deliveryCodeUrl}
            pickupCode={data.pickupCode}
            pickupCodeUrl={data.pickupCodeUrl}
            reclaimCode={data.reclaimCode}
            reclaimCodeUrl={data.reclaimCodeUrl}
            showError={showError}
            relatedDeliveryBusinessKey={data.relatedShipmentBusinessKey}
          />
          {canPutInBox(stateId) && data.deliveryCode && !delivered
            &&
            <PrintButton>
              {data.recipient?.identificationNumber}
              <div>
                <Barcode
                  className="BarcodeOutBox"
                  {...barcodeLabelOptions}
                  value={data.deliveryCode}
                />
              </div>
            </PrintButton>
          }
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default withRouter(AboutDelivery);
