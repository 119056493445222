import React from 'react';
import { Layout } from 'antd';

import ActionableDeliveries from './ActionableDeliveries';

const { Content } = Layout;

const ActionList = ({ branches, branchId, permissions, allowedColumns }) => {
  // const [loadingGeneralStats, setLoadingGeneralStats] = useState(false);
  // const [generalStats, setGeneralStats] = useState({});

  // useEffect(() => {
  //   setLoadingGeneralStats(true)
  //   API.GET(`Dashboard/lockers?branchId=${branchId}`)
  //     .then(response => {
  //       setGeneralStats(response.data)
  //       setLoadingGeneralStats(false)
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setLoadingGeneralStats(false)
  //     });
  // }, [branchId]);

  // const branch = branches.find(b => b.id === branchId)

  // return (
  //   <Layout>
  //     <Content>
  //       <Row gutter={24}>
  //         <Col xs={24} lg={12}>
  //           <FreeCompartments
  //             data={generalStats?.freeCompartments}
  //             loading={loadingGeneralStats}
  //             branchName={branch?.name}
  //           />
  //         </Col>
  //       </Row>
  //       <ActionableDeliveries
  //         branches={branches}
  //         branchId={branchId}
  //         permissions={permissions}
  //       />
  //     </Content>
  //   </Layout>
  // );
  return (
    <Layout>
      <Content>
        <ActionableDeliveries
          branches={branches}
          branchId={branchId}
          permissions={permissions}
          allowedColumns={allowedColumns}
        />
      </Content>
    </Layout>
  );
}

export default ActionList;
