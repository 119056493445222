import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { checkPermission } from '../../Utils';
import { PERMISSION } from '../../Constants';
import { 
  HomeIcon, ContractIcon, FilesIcon, GearIcon, InIcon, OutIcon, SendIcon,
} from '../shared/icons';
import logo from '../../assets/logo-midjan-white.png';
import logoDark from '../../assets/logo-midjan-dark.png';
import mLogo from '../../assets/logo-m.png';
import mLogoDark from '../../assets/logo-m-dark.png';
import sidebarLogo from '../../assets/sidebar-logo.svg';
import sidebarLogoDark from '../../assets/sidebar-logo-dark.svg';
import { useTheme } from '../shared/hooks'

const { Sider } = Layout;

const md = 768;

const Sidebar = ({ permissions, location }) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < md);
  const theme = useTheme()

  const isDarkMode = theme === 'dark'

  const onResize = () => {
    setCollapsed(window.innerWidth < md)
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label: children
        ? <FormattedMessage id={label} />
        : <NavLink to={key} className="no-underline"><FormattedMessage id={label} /></NavLink>,
      type,
    };
  }

  const userMenu = [
    checkPermission(permissions, PERMISSION.viewDashboard)
      ? getItem('Sidebar.Dashboard', '/dashboard', <HomeIcon />)
      : null,
    getItem('Home.ActionList', '/action-list', <ContractIcon />),
    getItem('Sidebar.Record', '/create-delivery', <OutIcon />),
    checkPermission(permissions, PERMISSION.createCollection)
      ? getItem('Sidebar.CreateCollection', '/collection', <InIcon />)
      : null,
    getItem('Sidebar.NotDelivered', '/not-delivered', <SendIcon />),
    getItem('Sidebar.AllShipments', '/delivery', <FilesIcon />),

    getItem('Sidebar.Settings', '/settings', <GearIcon />, [
      checkPermission(permissions, PERMISSION.readSender)
        ? getItem('Sidebar.Senders', '/admin/senders')
        : null,
      checkPermission(permissions, PERMISSION.readCompany)
        ? getItem('Sidebar.Companies', '/admin/companies')
        : null,
      checkPermission(permissions, PERMISSION.readBranch)
        ? getItem('Sidebar.Branches', '/admin/branches')
        : null,
      checkPermission(permissions, PERMISSION.adminGroup)
        ? getItem('Sidebar.Groups', '/admin/access-groups')
        : null,
      checkPermission(permissions, PERMISSION.readTemplate)
        ? getItem('Sidebar.TemplateEditor', '/template-editor')
        : null,
    ]),
  ];

  const getLogo = () => !isDarkMode
    ? collapsed
      ? mLogoDark
      : logoDark
    : collapsed
      ? mLogo
      : logo

  return (
    <Sider
      width={260}
      collapsedWidth={72}
      collapsed={collapsed}
      theme={theme}
    >
      <NavLink to={'/'} className="logo-wrapper">
        <img src={getLogo()} alt="logo" className="logo" />
      </NavLink>

      <Menu
        items={userMenu}
        selectedKeys={[location.pathname]}
        defaultSelectedKeys={['/action-list']}
        mode="inline"
      />

      <img src={isDarkMode ? sidebarLogo : sidebarLogoDark} alt="logo" className='sidebar-logo' />
    </Sider>
  );
};

export default withRouter(Sidebar);
