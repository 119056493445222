import dayjs from 'dayjs';
import { upperFirst } from 'lodash'
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { translations } from '../../Constants';
import { dateComparison, stringComparison, checkPermission, boolComparison } from '../../Utils';
import { useFormatMessage } from './hooks'
import { PERMISSION } from '../../Constants';

const dateFormat = 'DD-MM-YYYY HH:mm';

const dataIndexToKey = dataIndex => Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex

/**
 * useColumns hook for delivery tables
 * @param permissions array: user permissions
 * @param visibleColumns array: defines the initially visible columns and the order
 * @param allowedColumns map: defines what columns are allowed
 */
export const useColumns = (permissions = [], visibleColumns = [], allowedColumns = new Map()) => {
  const f = useFormatMessage()

  const columns = [
    {
      // title creates BUG using f() instead of FormattedMessage: https://my.origo.is/browse/ZMIDSD-262
      title: <FormattedMessage id="ActionDeliveries.TH.Recipient" />,
      dataIndex: ['recipient', 'name'],
      sorter: (a, b) =>
        stringComparison(a.recipient, b.recipient, recipient => recipient.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.Kennitala" />,
      dataIndex: ['recipient', 'identificationNumber'],
      sorter: (a, b) =>
        stringComparison(a.recipient, b.recipient, recipient => recipient.identificationNumber),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.RentalCar" />,
      dataIndex: ['rentalCar'],
      sorter: (a, b) =>
        boolComparison(a.rentalCar, b.rentalCar),
      sortDirections: ['ascend', 'descend'],
      render: rentalCar => (rentalCar ? 'X' : ''),
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.OrderType" />,
      dataIndex: 'orderType',
      render: orderType => (orderType ? upperFirst(orderType) : ''),
      sorter: (a, b) => stringComparison(a.orderType, b.orderType, orderType => orderType),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.SenderOrder" />,
      dataIndex: 'senderOrderID',
      sorter: (a, b) => stringComparison(a.senderOrderID, b.senderOrderID),
      sortDirections: ['ascend', 'descend'],
      render: num => <Link to={'/about-delivery/?businessKey=' + num} className="btn-link">{num}</Link>,
      width: 140,
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.BusinessKey" />,
      dataIndex: 'businessKey',
      sorter: (a, b) => stringComparison(a.businessKey, b.businessKey),
      sortDirections: ['ascend', 'descend'],
      width: 140,
    },
    {
      title: <FormattedMessage id="NotDelivered.PackageRegistered" />,
      dataIndex: 'createDate',
      render: createDate => dayjs(createDate).format(dateFormat),
      sorter: (a, b) => dateComparison(a.createDate, b.createDate),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.Location" />,
      dataIndex: 'location',
      sorter: (a, b) => stringComparison(a.location, b.location),
    },
    {
      title: <FormattedMessage id="NotDelivered.LastMessageSent" />,
      dataIndex: 'lastMessageSent',
      render: lastMessageSent => lastMessageSent == null
        ? f('ActionDeliveries.NoMessage')
        : dayjs(lastMessageSent.timeSentUTC).format(dateFormat),
      sorter: (a, b) => dateComparison(a.lastMessageSent, b.lastMessageSent),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: <FormattedMessage id="ActionDeliveries.TH.Status" />,
      dataIndex: 'currentState',
      render: currentState => translations[currentState.stateId]
        ? translations[currentState.stateId]
        : currentState.name,
      sorter: (a, b) => {
        const translatedA = translations[a.currentState.stateId]
          ? translations[a.currentState.stateId]
          : a.currentState.name;
        const translatedB = translations[b.currentState.stateId]
          ? translations[b.currentState.stateId]
          : b.currentState.name;
        return translatedA.localeCompare(translatedB);
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: <FormattedMessage id="CreateDelivery.Sender" />,
      dataIndex: 'sender',
      render: sender => (sender ? sender.name : ''),
      sorter: (a, b) => stringComparison(a.sender, b.sender, sender => sender.name),
      sortDirections: ['ascend', 'descend']
    },
    {
      // title creates BUG using f() instead of FormattedMessage: https://my.origo.is/browse/ZMIDSD-262
      title: <FormattedMessage id="ActionDeliveries.TH.Description" />,
      dataIndex: ['description'],
      sorter: (a, b) =>
        stringComparison(a.description, b.description),
      sortDirections: ['ascend', 'descend']
    },
  ]
    .map(col => ({
      ...col,
      key: dataIndexToKey(col.dataIndex),
      visible: visibleColumns.length ? visibleColumns.includes(dataIndexToKey(col.dataIndex)) : true
    }))
    .filter(col => allowedColumns.has(col.key) ? allowedColumns.get(col.key) : true)
    .filter(col => col.key === 'orderType' ? checkPermission(permissions, PERMISSION.readCollection) : true)

  // put invisible columns at the end
  const sortedColumns = columns.sort((a, b) => {
    const indexA = visibleColumns.indexOf(a.key) === -1 ? Infinity : visibleColumns.indexOf(a.key)
    const indexB = visibleColumns.indexOf(b.key) === -1 ? Infinity : visibleColumns.indexOf(b.key)
    return indexA - indexB
  })

  return sortedColumns
}

export const searchableProps = [
  ['recipient', 'name'],
  'senderOrderID',
  'location',
  ['currentState', 'name'],
  'orderType'
]

