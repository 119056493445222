import React from 'react';
import { Row, Col, Typography, Table, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash';

import { labelStyle } from './style';
import { sizeTranslations } from '../shared/LockerBoxes'

const { Text } = Typography;

function groupBy(list, keyGetter) {
    list = list ? list : []
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

const FreeCompartments = ({ data, loading, branchName }) => {
  const intl = useIntl();
  const f = (id) => intl.formatMessage({ id })
  // data =  [
  //   {
  //     "siteName": "Búnaður",
  //     "size": "11x4",
  //     "sizeText": "28x10",
  //     "numAvailable": 0,
  //     "numTotal": 2,
  //     "lockerType": "Apex"
  //   },
  //   {
  //     "siteName": "Búnaður",
  //     "size": "11x10",
  //     "sizeText": "28x25",
  //     "numAvailable": 0,
  //     "numTotal": 1,
  //     "lockerType": "Apex"
  //   },
  //   {
  //     "siteName": "Aðgangskort",
  //     "size": "3x3",
  //     "sizeText": "7x7",
  //     "numAvailable": 26,
  //     "numTotal": 26,
  //     "lockerType": "KeyCafe"
  //   }
  // ]
  const lockerTotals = data?.map(lt => {
    const sizeName= sizeTranslations[lt.size]
    return {
      ...lt,
      sizeName,
    }
  })
  // const dataSource = [{"Box.Size.Small":"something"}, {}, {}, {}]
  const groupedBySite = Array.from(groupBy(lockerTotals, l => l.siteName));
  const dataSource = groupedBySite.map(([siteName, lockerTotals]) => {
    let ret = {siteName, key:siteName}
    //create the data values that will be looked up by columns
    lockerTotals.forEach(lockerTotal => {
      ret[lockerTotal.sizeName] = {available:lockerTotal.numAvailable, total:lockerTotal.numTotal}
    });
    return ret  
  })
  // const dataSource = map(data, (lockerTotal, i) => {
  //       const name = sizeTranslations[locker.size]
  //   return {
  //     key: i,
  //   }
  // })
  //

  //changed api call to simplify this
  // const dataSource = map(data, (compartment, i) => {
  //   const freeLockers = compartment.devices
  //     .reduce((acc, device) => acc.concat(device.lockers), [])
  //     .reduce((acc, locker) => {
  //       const name = sizeTranslations[locker.size]
  //       const getIndex = i => acc[name]?.[i]
  //
  //       const available = locker.isAvailable === 'true'
  //         ? getIndex(0) ? getIndex(0) + 1 : 1
  //         : 0
  //
  //       const total = getIndex(1) ? getIndex(1) + 1 : 1
  //
  //       return {
  //         ...acc,
  //         [name]: [available, total],
  //       }
  //     }, {});
  //
  //   return {
  //     key: i,
  //     ...compartment,
  //     ...freeLockers,
  //   }
  // })

  // const renderFreeLockers = (text, record, index) => Array.isArray(value) ? `${value[0]} / ${value[1]}` : null
  const renderFreeLockers = (value) => value ? `${value.available} / ${value.total}` : '-'
  // const renderFreeLockers = (text, record, index) => {
  //     console.log(text)
  //     console.log(record)
  //     return `${text} ${record} ${index}`
  // }

  const columns = [
    {
      title: f('Home.Location'),
      dataIndex: 'siteName',
      // key: 'siteName',
    },
    {
      title: f('Box.Size.Keys'),
      dataIndex: 'Box.Size.Keys',
      // key: 'Box.Size.Keys',
      render: renderFreeLockers,
    },
    {
      title: f('Box.Size.Small'),
      // key: 'Box.Size.Small',
      dataIndex: 'Box.Size.Small',
      render: renderFreeLockers,
    },
    {
      title: f('Box.Size.Mid'),
      // key: 'Box.Size.Mid',
      dataIndex: 'Box.Size.Mid',
      render: renderFreeLockers,
    },
    {
      title: f('Box.Size.Big'),
      // key: 'Box.Size.Big',
      dataIndex: 'Box.Size.Big',
      render: renderFreeLockers,
    },
  ];

  if (loading) return <Spin />;
  
  return (
    <Row gutter={24} className="free-compartments">
      <Col xs={24}>
        <Text strong style={labelStyle}>
          <FormattedMessage id="Home.FreeCompartments" />
        </Text>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className="small-table"
          rowClassName={rec => rec.siteAddress1 === branchName ? 'highlight-row' :  ''}
        />
      </Col>
    </Row>
  )
}

export default FreeCompartments;
