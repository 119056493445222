import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Layout, Row, Col,Input, Tooltip, Select, Spin, Checkbox } from 'antd';
import { map, get } from 'lodash';

import { API } from '../../AxiosWrapper';
import RegisterTabs from './RegisterTabs';
import BoxInfo from './BoxInfo';
import { phoneValidator } from '../../Utils';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form;
const { Option } = Select;


const CreateDelivery = ({ branchId, companyId, senders, permissions, fieldConfiguration = new Map(), defaultSenderId, defaultBranchId }) => {
  const [form] = Form.useForm();
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();

  const [loading, setLoading] = useState(false);
  const [senderId, setSenderId] = useState(defaultSenderId);
  const [lockers, setLockers] = useState(null);

  const setLockersAndUpdate = (lockers) => {
    if (!lockers) {
      setLockers(null)
      return
    }
    const currentBoxSize = form.getFieldValue("boxSize")
    if (!lockers.find(locker => locker.size === currentBoxSize)) {
      form.setFieldValue("boxSize", get(lockers, '[0].size'))
      form.setFieldValue("oldBoxSize", get(lockers, '[0].size'))
    }
    setLockers(lockers)
  }

  const fieldShown = (name) => {
    return fieldConfiguration.get(name)?.used ?? false
  }

  const isRequired = (name) => {
    return fieldConfiguration.get(name)?.required ?? false
  }

  const getBoxSizes = (branchId) => {
    setLoading(true);
    API.GET(`Site/${branchId}/lockerTotals`)
      .then(response => setLockersAndUpdate(get(response, 'data', [])))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    if (senders.length === 1)
      setSenderId(senders[0].id);  
  }, [senders])

  useEffect(() => {
    if (branchId === 0) return;
    getBoxSizes(branchId);
  }, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  //this should be removed
  const emailOrPhone = () => {
    const phone = form.getFieldValue('phone')
    const email = form.getFieldValue('email')

    const valid = (phone || email)
    return valid
      ? Promise.resolve()
      : Promise.reject(new Error(f('CreateDelivery.EmailOrPhoneRequired')));
  }

  if (!branchId) return "Branch is not selected.";

  if (loading || !lockers) return <Spin size="large" />;

  return (
    <Layout.Content>
      <h2 style={{ marginBottom: 16 }}><FormattedMessage id="CreateDelivery.DeliveryTitle" /></h2>

      <Row>
      <Col xs={24} lg={12}>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          senderId,
          boxSize: get(lockers, '[0].size'),
          oldBoxSize: get(lockers, '[0].size'),
          // oldBoxSize: form.getFieldValue('boxSize'),
        }}
        className="form-style"
        preserve={true}
      >
        <Item
          name="senderId"
          hidden= {senders.length === 1 ? true : false}
          label={f("CreateDelivery.Sender")}
          hasFeedback
          rules={[{
            required: true, message: f("CreateDelivery.SenderRequired")
          }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(value) => setSenderId(value)}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {map(senders, (sender) => (
              <Option key={sender.id} value={sender.id}>
                {sender.name}
              </Option>
            ))}
          </Select>
        </Item>
        {fieldShown("kennitala") &&
          <Item
            name="kennitala"
            label={f("ActionDeliveries.TH.Kennitala")}
            hasFeedback
            rules={[
              {
                required: isRequired("kennitala"), 
                message: f("Validation.NationalIDRequired")
              },
              {
                type: "string",
                pattern: /^\s*\d{6}\s*-?\s*\d{4}\s*$/,
                message: f("Validation.NationalIDFormat"),
              },
            ]}
          >
            <Input />
          </Item>
        }
        {fieldShown("recipientName") &&
          <Item
            label={<FormattedMessage id="CreateDelivery.Recipient" />}
            hasFeedback
            name="recipient"
            rules={[
            {
              required: isRequired("recipientName"), 
              message: f('CreateDelivery.RecipientNameRequired'),
            },
            {
              whitespace: true, message: f('CreateDelivery.FieldWhitespaceError'),
            },
            ]}
          >
            <Input />
          </Item>
        }
        {fieldShown("street") &&
        <Item
          name="street"
          label={<FormattedMessage id="CreateDelivery.Address" />}
          hasFeedback
          rules={[
            {
              required: isRequired("street"), 
              message: f('CreateDelivery.RecipientAddressRequired'),
            },
            {
              whitespace: true, message: f('CreateDelivery.FieldWhitespaceError'),
            },
          ]}
        >
          <Input />
        </Item>
        }
        {fieldShown("email") &&
          <Item
            name="email"
            rules={[
              {
                required: isRequired("email"), 
                message: f('CreateDelivery.EmailRequired'),
              },
              {
                type: 'email', message: f('CreateDelivery.EmailError'),
              }
            ]}
            label={
              <Tooltip title={<FormattedMessage id="CreateDelivery.EmailTooltip" />}>
                <FormattedMessage id="CreateDelivery.Email" />
              </Tooltip>
            }
            hasFeedback
          >
            <Input />
          </Item>
        }
        {fieldShown("phone") &&
          <Item
            name="phone"
            rules={[
              {
                required: isRequired("phone"), message: f('Validation.PhoneRequired')
              },
              {
                validator: phoneValidator, message: f('Validation.PhoneInvalid')
              },
            ]}
            label={
              <Tooltip title={<FormattedMessage id="CreateDelivery.MobileTooltip" />}>
                <FormattedMessage id="CreateDelivery.Mobile" />
              </Tooltip>
            }
            hasFeedback
          >
            <Input />
          </Item>
        }
        {fieldShown("emailOrPhone") &&
          <>
            <Item
              name="email"
              rules={[
                {
                  validator: emailOrPhone, message: f('CreateDelivery.EmailOrPhoneRequired')
                },
                {
                  type: 'email', message: f('CreateDelivery.EmailError'),
                },
              ]}
              label={
                <Tooltip title={<FormattedMessage id="CreateDelivery.EmailTooltip" />}>
                  * <FormattedMessage id="CreateDelivery.Email" />
                </Tooltip>
              }
              hasFeedback
            >
              <Input />
            </Item>
            <Item
              name="phone"
              rules={[
                {
                  validator: emailOrPhone, message: f('CreateDelivery.EmailOrPhoneRequired')
                },
                {
                  validator: phoneValidator, message: f('Validation.PhoneInvalid')
                },
              ]}
              label={
                <Tooltip title={<FormattedMessage id="CreateDelivery.MobileTooltip" />}>
                  * <FormattedMessage id="CreateDelivery.Mobile" />
                </Tooltip>
              }
              hasFeedback
            >
              <Input />
            </Item>
          </>
        }
        <hr />
        {fieldShown("senderOrderID") &&
          <Item
            name="senderOrderID"
            label={<FormattedMessage id="CreateDelivery.OrderNumber" />}
            hasFeedback
            rules={[
              {
                required: isRequired("senderOrderID"), message: f('CreateDelivery.OrderNumberRequired'),
              },
              {
                whitespace: true, message: f('CreateDelivery.FieldWhitespaceError'),
              },
            ]}
          >
            <Input />
          </Item>
        }
        {fieldShown("description") &&
          <Item
            name="description"
            label={<FormattedMessage id="CreateDelivery.OrderDescription" />}
            hasFeedback
            rules={[
              {
                required: isRequired("description"), message: f('CreateDelivery.OrderDescriptionRequired'),
              },
              {
                whitespace: true, message: f('CreateDelivery.FieldWhitespaceError'),
              },
            ]}
          >
            <Input />
          </Item>
        }
        {fieldShown("rentalCar") &&
          <Item
            name="rentalCar"
            hasFeedback
            valuePropName="checked"
          >
            {/* <Checkbox checked={rentalCar}> */}
            <Checkbox>
              {f('ActionDeliveries.TH.RentalCar')}
            </Checkbox>
          </Item>
        }
        <RegisterTabs
          form={form}
          setLoading={setLoading}
          permissions={permissions}
          branchId={branchId}
          companyId={companyId}
          defaultBranchId={defaultBranchId}
          lockers={lockers}
        />
      </Form>
      </Col>
      <Col xs={24} lg={12}>
        <BoxInfo lockers={lockers}/>
      </Col>
      </Row>
    </Layout.Content>
  );
}

export default CreateDelivery;
