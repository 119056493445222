import React, { useState, useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { isEmpty } from 'lodash';

import AuthRequired from '../AuthRequired';
import { API } from '../AxiosWrapper';
import history from '../history';
import ActionList from './Home/ActionList';
import Dashboard from './Home/Dashboard';
import SiteHeader from './Header/SiteHeader';
import Sidebar from './Sidebar/Sidebar';
import AllShipments from './AllShipments/AllShipments';
import AboutDelivery from './AboutDelivery/AboutDelivery';
import CreateDelivery from './CreateDelivery/CreateDelivery';
import CreateSender from './Admin/CreateSender';
import Collection from './RegisterReturn/RegisterReturn';
import ErrorNotFound from './ErrorNotFound';
import NotDelivered from './NotDelivered/NotDelivered';
import SearchResults from './SearchResults/SearchResults';
import Profile from './Profile';
import Senders from './Admin/Senders';
import AccessGroups from './Admin/AccessGroups';
import Branches from './Admin/Branches';
import Companies from './Admin/Companies';
import CreateCompany from './Admin/CreateCompany';
import CreateBranch from './Admin/CreateBranch';
import CreateUser from './Admin/CreateUser';
import TemplateEditor from './Templates/TemplateEditor';
import Login from './Login';
import { useHandleApiError, useTheme } from './shared/hooks'

const { Content } = Layout;

const fieldMapping = new Map([
  ["recipient.kennitala", "kennitala"],
  ["rentalCar", "rentalCar"], //TODO HACK
  ["recipient.name", "recipientName"],
  ["recipient.phone", "phone"],
  ["recipient.email", "email"],
  ["recipient.emailOrPhone", "emailOrPhone"],
  ["recipient.street", "street"],
  ["senderOrderID", "senderOrderID"],
  ["description", "description"],
])

const columnMapping = new Map([
  ["recipient.kennitala", "recipient.identificationNumber"],
  ["recipient.name", "recipient.name"],
  ["recipient.phone", "recipient.phone"],
  ["recipient.email", "recipient.email"],
  ["recipient.emailOrPhone", null],
  ["recipient.street", "recipient.street"],
  ["senderOrderID", "senderOrderID"],
  ["description", "description"],
  ["rentalCar", "rentalCar"],
])

const Routes = ({ locale, setLocale, setUserTheme }) => {
  const handleApiError = useHandleApiError();
  const theme = useTheme()

  const [authorized, setAuthorized] = useState(false);
  const [branches, setBranches] = useState([]);
  const [senders, setSenders] = useState([]);
  const [fieldConfiguration, setFieldConfiguration] = useState(new Map());
  const [user, setUser] = useState();

  const resolveDefaultSender = (userDefaultSenderId, senders) => {
    if (isEmpty(senders)) return userDefaultSenderId;

    let defaultId = userDefaultSenderId
    if (senders.length === 1) {
      defaultId = senders[0].id
    }
    if (!senders.find((s) => s.id === defaultId)) {
      defaultId = null;
    }
    return defaultId;
  }

  const resolveDefaultBranch = (userDefaultBranchId, branches) => {
    if (branches.length === 1) return branches[0].id

    if (branches.some(b => b.id === userDefaultBranchId)) return userDefaultBranchId

    if (localStorage.branchId) return parseInt(localStorage.getItem('branchId'))

    return 0 // all branches
  }

  const resolveFieldConfiguration = (serverConfig) => {
    serverConfig ??= {}
    const serverMap = new Map(Object.entries(serverConfig))
    //little bit of sanity, create default values for those values that don't come from the server
    fieldMapping.forEach((v, key) => {
      if (!serverMap.has(key)) {
        console.error(`validation for ${key} did not come from server`)
        //add a default
        serverMap[key] = { used: false, required: false, key: key, columnKey: key }
      }
    })
    //log when frontend is missing the correct handler
    serverMap.forEach((v, key) => {
      if (!fieldMapping.has(key)) {
        console.error(`validation for ${key} is not known in frontend`)
      }
    })
    //There is no easy way to change a map to array
    //and we want to filter out stuff
    const stupidArray = Array.from(serverMap)

    return new Map(stupidArray
      .filter(([key, junk]) => fieldMapping.has(key)) //filter out if fieldMapping doesn't support it
      .map(([key, validation]) => [fieldMapping.get(key), { ...validation, columnKey: columnMapping.get(key) ?? key }]))
  }

  const getBranches = () => API.GET('site')
    .then(response => response.data)
    .catch(error => handleApiError(error, 'branches'))

  const getSenders = () => API.GET('sender')
    .then(response => response.data)
    .catch(error => handleApiError(error, 'senders'))

  const getUser = () => API.GET('users/current')
    .then(response => response.data)
    .catch(error => handleApiError(error, 'user'))

  const getFieldConfiguration = (branch) => {
    let url = 'configuration/validations'
    if (branch) {
      url = url + '?branchId=' + branch
    }
    return API.GET(url)
      .then(response => response.data)
      .catch(error => handleApiError(error, 'configuration'))
  }

  // for testing on frontend only
  // eslint-disable-next-line no-unused-vars
  const getFieldConfigurationTesting = () => {
    const lsh = {
      "recipient.kennitala": { used: true, required: true, key: "recipient.kennitala" },
      "recipient.name": { used: true, required: false, key: "recipient.name" },
      "recipient.phone": { used: true, required: true, key: "recipient.phone" },
      "recipient.emailOrPhone": { used: false, required: false, key: "recipient.emailOrPhone" },
      "recipient.email": { used: false, required: false, key: "recipient.email" },
      "recipient.street": { used: false, required: false, key: "recipient.street" },
      "senderOrderID": { used: true, required: false, key: "senderOrderID" },
      "description": { used: false, required: false, key: "description" },
    };

    const origo = {
      "recipient.kennitala": { used: false, required: false, key: "recipient.kennitala" },
      "recipient.name": { used: true, required: true, key: "recipient.name" },
      "recipient.phone": { used: false, required: false, key: "recipient.phone" },
      "recipient.emailOrPhone": { used: true, required: false, key: "<notused>" },
      "recipient.email": { used: false, required: false, key: "recipient.email" },
      "recipient.street": { used: true, required: false, key: "recipient.address" },
      "senderOrderID": { used: true, required: true, key: "senderOrderID" },
      "description": { used: true, required: true, key: "description" },
    };
    const fieldConfigurations = { origo: origo, lsh: lsh }
    return fieldConfigurations.lsh;
    // return fieldConfiguration.origo;
  };

  const loadData = async () => {
    try {
      const branches = await getBranches() || []
      setBranches(branches)

      const senders = await getSenders() || []
      setSenders(senders)

      const user = await getUser()

      const defaultBranchId = resolveDefaultBranch(user.defaultBranchId, branches)
      const fieldConfiguration = await getFieldConfiguration(defaultBranchId)
      // const fieldConfiguration = await getFieldConfigurationTesting()
      const config = resolveFieldConfiguration(fieldConfiguration)

      setFieldConfiguration(config)
      setUser({
        ...user,
        defaultBranchId: defaultBranchId,
        defaultSenderId: resolveDefaultSender(user.defaultSenderId, senders),
      })
      setAuthorized(true)
    } catch (error) {
      //TODO we need this log when developing but should it be hidden in prod?
      console.error(error)
      setAuthorized(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem('loggingOut');
    loadData();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const handleBranchChange = async (id) => {
    //should this be an effect?
    const fieldConfiguration = await getFieldConfiguration(id)
    // const fieldConfiguration = await getFieldConfigurationTesting()
    const config = resolveFieldConfiguration(fieldConfiguration)
    setFieldConfiguration(config)
    setUser({ ...user, defaultBranchId: id })
    localStorage.setItem('branchId', id)
  };

  const findCompanyIdForBranchId = (branches, branchId) => {
    let branch = branches.find(({ id }) => (id === branchId));
    if (branch) return branch.companyId;
    return 0;
  };

  if (!authorized || !user || user?.defaultBranchId === undefined) return null

  const fieldConfigurationArray = Array.from(fieldConfiguration)
  const allowedColumns = new Map(fieldConfigurationArray.map(([key, values]) => [values.columnKey, values.used]))

  return (
    <Router history={history}>
      <Layout style={{ minHeight: '100vh' }} className={theme}>
        <Route path="/admin"
          children={({ match }) => (
            <AuthRequired
              user={user}
              component={<Sidebar permissions={user.permissions} />}
            />
          )}
        />
        <Layout>
          <Route path="/admin" children={({ match }) => (
            <SiteHeader
              user={user}
              branchId={user.defaultBranchId}
              branches={branches}
              locale={locale}
              handleBranchChange={handleBranchChange}
              setLocale={setLocale}
              setUserTheme={setUserTheme}
            />
          )}
          />
          <Content className="main-content">
            <Switch>
              <Route exact path="/"><Redirect to="/dashboard" /></Route>
              <Route exact path="/home"><Redirect to="/dashboard" /></Route>
              <Route
                path="/login"
                render={() => <Login />}
              />
              <Route
                exact={true}
                path="/action-list"
                render={() => (
                  <ActionList
                    branches={branches}
                    branchId={user.defaultBranchId}
                    permissions={user.permissions}
                    allowedColumns={allowedColumns}
                  />
                )}
              />
              <Route
                path="/dashboard"
                render={() => <Dashboard branches={branches} branchId={user.defaultBranchId} />}
              />
              <Route
                exact={true}
                path="/delivery"
                render={() => (
                  <AllShipments
                    defaultSenderId={user.defaultSenderId}
                    branchId={user.defaultBranchId}
                    permissions={user.permissions}
                    senders={senders}
                    allowedColumns={allowedColumns}
                  />
                )}
              />
              <Route
                path="/search-shipment"
                render={() => <SearchResults permissions={user.permissions} allowedColumns={allowedColumns} />}
              />
              <Route
                exact={true}
                path="/admin/create-branch"
                render={() => <CreateBranch branches={branches} setBranches={setBranches} />}
              />
              <Route
                exact={true}
                path="/admin/create-company"
                render={() => <CreateCompany />}
              />
              <Route
                exact={true}
                path="/admin/create-user"
                render={() => <CreateUser permissions={user.permissions} />}
              />
              <Route
                exact={true}
                path="/template-editor"
                render={() => <TemplateEditor senders={senders} branches={branches} locale={locale} />}
              />
              <Route
                exact={true}
                path="/create-delivery"
                render={() => (
                  <CreateDelivery
                    branchId={user.defaultBranchId}
                    senders={senders}
                    companyId={findCompanyIdForBranchId(branches, user.defaultBranchId)}
                    defaultBranchId={user.defaultBranchId}
                    defaultSenderId={user.defaultSenderId}
                    permissions={user.permissions}
                    fieldConfiguration={fieldConfiguration}
                  />
                )}
              />
              <Route
                exact={true}
                path="/collection"
                render={() => (
                  <Collection
                    branchId={user.defaultBranchId}
                    companyId={findCompanyIdForBranchId(branches, user.defaultBranchId)}
                    defaultSenderId={user.defaultSenderId}
                  />
                )}
              />
              <Route
                exact={true}
                path="/admin/create-sender"
                render={() => <CreateSender />}
              />
              <Route
                exact={true}
                path="/about-delivery"
                render={() => (
                  <AboutDelivery
                    senders={senders}
                    branches={branches}
                    fieldConfiguration={fieldConfiguration}
                  />
                )}
              />
              <Route
                exact={true}
                path="/not-delivered"
                render={() => (
                  <NotDelivered
                    branchId={user.defaultBranchId}
                    permissions={user.permissions}
                    defaultSenderId={user.defaultSenderId}
                    senders={senders}
                    allowedColumns={allowedColumns}
                  />
                )}
              />
              <Route
                exact={true}
                path="/settings/profile"
                render={() => (
                  <Profile
                    user={user}
                    senders={senders}
                    branches={branches}
                    permissions={user.permissions}
                    onUpdateUser={loadData}
                    handleBranchChange={handleBranchChange}
                  />
                )}
              />
              <Route
                exact={true}
                path="/admin/access-groups"
                render={() => <AccessGroups permissions={user.permissions} />}
              />
              <Route
                exact={true}
                path="/admin/senders"
                render={() => <Senders senders={senders} permissions={user.permissions} />}
              />
              <Route
                exact={true}
                path="/admin/branches"
                render={() => <Branches branches={branches} permissions={user.permissions} />}
              />
              <Route
                exact={true}
                path="/admin/companies"
                render={() => <Companies permissions={user.permissions} />}
              />
              <Route
                exact={true}
                path="*"
                status={404}
                render={() => <ErrorNotFound />}
              >
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  )
};

export default Routes;
// const fieldConfigurationAll = {
//   "kennitala": { used: true, required: true, key: "recipient.identificationNumber"},
//   "recipientName": { used: true, required: false, key: "recipient.name" },
//   "phone": { used: true, required: true, key: "recipient.phone" },
//   "emailOrPhone": { used: false, required: false, key: "<notused>"},
//   "email": { used: false, required: false, key: "recipient.email" },
//   "street": { used: false, required: false, key: "recipient.address" },
//   "senderOrderID": { used: true, required: false, key: "senderOrderID" },
//   "description": { used: false, required: false, key: "description" },
// };
//

// LSH
// const fieldConfiguration = {
//   "kennitala": { used: true, required: true, key: "recipient.identificationNumber" },
//   "recipientName": { used: true, required: false, key: "recipient.name" },
//   "phone": { used: true, required: true, key: "recipient.phone" },
//   "emailOrPhone": { used: false, required: false, key: "<notused>" },
//   "email": { used: false, required: false, key: "recipient.email" },
//   "street": { used: false, required: false, key: "recipient.address" },
//   "senderOrderID": { used: true, required: false, key: "senderOrderID" },
//   "description": { used: false, required: false, key: "description" },
// };

// origo
// const fieldConfiguration = {
//     "kennitala": {used: false, required:false, key:"recipient.identificationNumber"},
//     "recipientName": {used: true, required:true, key:"recipient.name"},
//     "phone":{used: false, required:false, key:"recipient.phone"},
//     "emailOrPhone":{used: true, required:false, key: "<notused>"},
//     "email":{used: false, required:false, key: "recipient.email"},
//     "street":{used: true, required:false, key: "recipient.address"},
//     "senderOrderID":{used: true, required:true, key:"senderOrderID"},
//     "description":{used: true, required:true, key:"description"},
// };

